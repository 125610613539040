import React, { useEffect } from "react";
import { redirectTo } from "src/helpers/route";
const Redirect = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      redirectTo("/links");
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return <p>Redirecionando...</p>;
};
export default Redirect;
/*
import React from "react";
import YoutubeVideo from "src/components/YoutubeVideo";
import { FacebookProvider, Comments } from "react-facebook";

import SEO from "src/components/SEO";
import About from "src/sections/About";
import Footer from "src/sections/Footer";
import {
  BASE_URL,
  NAME,
  FACEBOOK_APP_ID,
  MARATONA_JS_3,
} from "src/helpers/config";

import Dates from "src/components/Dates";
import dates from "../dates.json";

import "../maratona.scss";

const Dia2 = () => {
  // const videoId = "SqtbBFYLCuI"; // convite
  // const videoId = "_YoeBi3_r9s"; // dia 1
  const videoId = "YmvTmNOQUGc"; // dia 2

  const seoProps = {
    title: `Maratona JS 3 - Dia 02 - ${NAME}`,
    canonical: `${BASE_URL}/mararona-js-3/dia-2/`,
    description: `Você também consegue se você se dedicar! - ${MARATONA_JS_3}.`,
    image: `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
  };
  return (
    <div className="maratona-js-3 video-page">
      <SEO {...seoProps} />
      <div className="wrapper">
        <div className="header">
          <div className="logo">
            <img
              loading="lazy"
              src="/images/maratona-js-3-logo-white.png"
              alt={`Logo ${MARATONA_JS_3}`}
            />
          </div>
        </div>
        <Dates dates={dates} />
        <h1>Dia 02</h1>
        <YoutubeVideo id={videoId} />
        <div className="description">
          <h3>Descrição:</h3>
          <p>
            <a
              href="https://github.com/emersonbroga/react-typing"
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              Repositório no GitHub!
            </a>
          </p>
          <p>
            <a
              href="https://raw.githubusercontent.com/emersonbroga/react-typing/main/src/resources/words.json"
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              Download do Arquivo Words.json
            </a>
          </p>
          <p>
            <a
              href="https://emersonbroga.com/youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              Me siga no youtube para mais conteúdos tão bons quanto esse!
            </a>
          </p>
        </div>

        <div className="comments">
          <h3>Comentários:</h3>
          <FacebookProvider appId={FACEBOOK_APP_ID}>
            <Comments href={seoProps.canonical} width="100%" />
          </FacebookProvider>
        </div>
      </div>

      <About />
      <Footer />
    </div>
  );
};

export default Dia2;

*/
